<template>
  <div class="text-left">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="p-mt-4 p-mb-4"
    />
    <div>
      <Button
        type="button"
        label="Update"
        @click="$router.push({ path: '/qrcode/add' })"
      >
        {{ i18n.$t('Add a QR Code') }}
      </Button>
    </div>
    <table class="table-auto mt-4" v-if="data.length > 0">
      <thead>
        <tr class="border">
          <td>{{ i18n.$t('Updated At') }}</td>
          <td>{{ i18n.$t('QR Code') }}</td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        <tr class="border" v-for="(item, idx) in data" :key="idx">
          <td>
            <span>{{ displayDate(item.updated_at) }}</span>
          </td>
          <td>
            <vue-qrcode :value="item.qrcode" :width="75" />
          </td>
          <td>
            <ButtonConfirm @accept="onDelete(item._id)">
              <Button type="button" label="Update">
                {{ i18n.$t('Delete') }}
              </Button>
            </ButtonConfirm>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import VueQrcode from 'vue3-qrcode';
import { FETCH_QRCODES, GET_QRCODES, GET_USERDATA } from '../../store/types';
import { API } from '@/plugins/api';
import useI18n from '@/plugins/useI18n';
import { formatSimpleDate } from '../../plugins/utility';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'QR code list', to: '/qrcode' }]
  }),
  components: {
    VueQrcode
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();
    const data = computed(() => store.getters[GET_QRCODES]);
    const userData = computed(() => store.getters[GET_USERDATA]);

    store.dispatch(FETCH_QRCODES, userData.value.id);

    function displayDate(stamp) {
      return formatSimpleDate(stamp * 1000);
    }

    const api = new API();
    function onDelete(id) {
      api.delete('qrcode', id, FETCH_QRCODES);
    }

    return { i18n, data, onDelete, displayDate };
  }
};
</script>
<style lang="scss" scoped>
table {
  width: 100%;
}
td {
  padding: 5px;
}
</style>